<template>
    <div>
        <div class="filters">
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\quizes\\list.filter_title') }}</v-toolbar-title>
            </v-toolbar>
            <v-row class="mx-0 mb-4">
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.blogger"
                        :label="$t('src\\views\\quizes\\list.blogger')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.userName"
                        :label="$t('src\\views\\quizes\\list.userName')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.userPhone"
                        :label="$t('src\\views\\quizes\\list.userPhone')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.userEmail"
                        :label="$t('src\\views\\quizes\\list.userEmail')"
                        hide-details
                    />
                </v-col>
                <v-col sm="12" class="d-flex justify-end">
                    <v-btn
                        color="primary"
                        @click="filterApply">{{ $t('src\\views\\quizes\\list.filter_apply') }}</v-btn>
                    &nbsp;&nbsp;
                    <v-btn
                        color=""
                        @click="filterReset">{{ $t('src\\views\\quizes\\list.filter_reset') }}</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-data-table
            :headers="headers"
            :items="entities"
            :server-items-length="entitiesCount"
            :items-per-page="itemsPerPage"
            class="quizes-list"
            :loading="loading"
            :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
            @pagination="paginate"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('src\\views\\quizes\\list.494') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="exportToCsv">{{ $t('src\\views\\quizes\\list.export') }}</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.blogger`]="{ item }">
                {{ item.blogger ? item.blogger.social : '' }} ({{ item.blogger ? item.blogger.phone : '' }})
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon color="primary" @click="edit(item._id)">mdi-eye</v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';

    export default {
        name: 'QuizList',
        components: {
        },
        data: () => ({
            ACCESS,
            headers: [],
            page: 1,
            itemsPerPage: 50,
            loading: true,
            filter: {},
            filterApplied: {}
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('quizes', ['entities', 'entitiesCount']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            this.headers = [
                { text: this.$t('src\\views\\quizes\\list.createdAt'), value: 'createdAt', sortable: false },
                { text: this.$t('src\\views\\quizes\\list.blogger'), value: 'blogger', sortable: false },
                { text: this.$t('src\\views\\quizes\\list.userName'), value: 'userName', sortable: false },
                { text: this.$t('src\\views\\quizes\\list.userPhone'), value: 'userPhone', sortable: false },
                { text: this.$t('src\\views\\quizes\\list.userEmail'), value: 'userEmail', sortable: false },
            ]
            if(this.user && this.user.adminAccess && this.user.adminAccess.quizes >= ACCESS.READ) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async getQuizes() {
                this.loading = true;
                await store.dispatch('quizes/fetch', { filter: this.filterApplied, page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async exportToCsv() {
                this.loading = true;
                const data = await store.dispatch('quizes/exportToCsv', { filter: this.filterApplied });
                if (data.file) {
                    window.open(`${ process.env.VUE_APP_STATIC_URL }/${ data.file }`, '_blank');
                }
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.getQuizes();
            },
            async filterApply() {
                this.filterApplied = this.filter;
                await this.getQuizes();
            },
            async filterReset() {
                this.filterApplied = {};
                await this.getQuizes();
            },
            async edit(id) {
                await this.$router.push({ name: 'quiz-detail', params: { id } });
            }
        }
    }
</script>
